@font-face {
    font-family: VisbyCF;
    font-weight: bold;
    src: url("../fonts/VisbyCF-Bold.otf") format("opentype");
}

@font-face {
    font-family: VisbyCF;
    src: url("../fonts/VisbyCF-Regular.otf") format("opentype");
}

@font-face {
    font-family: VisbyCF;
    font-weight: lighter;
    src: url("../fonts/VisbyCF-Light.otf") format("opentype");
}

html, html body, body {
    font-family: 'VisbyCF', serif;
    font-size: 20px;
    color:#000;
}

.top {
    background:url('../images/header.png') no-repeat center center;
    background-size: cover;
    color:#FFF;
}

.top h1{
    font-size:40px;
    font-weight:bold;
}

.logo {max-width: 320px;}

.form-control {border-radius: 0px; border:#7586ae 2px solid;}
#btnsubmit-name {background: #1e4385; border: #1e4385 1px solid; border-radius: 0px; font-family:'VisbyCF', serif; font-weight:bold; font-size: 30px;}
.content {
    background:#FFF;
    font-weight:bold
}

.disclaimer {font-size:14px; line-height: 1em;}

.bottom {height:50px;}

@media (max-width: 575.98px)
{   
    .logo {max-width: 100%;}
    .form-control {font-size:0.9em; }
    .form-group {margin-bottom: 0.8rem;}
    .top h1{
        font-size:23px;
    }
    #phone {margin-top: 20px;}
}